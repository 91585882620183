import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from 'axios'
import Vue from 'vue'
import router from '../../../../router'

export default function useMaterialRequestsList() {
  const userToken = Vue.$cookies.get('userToken')
  const toast = useToast()
  const refMaterialRequestListTable = ref(null)
  const refMaterialRequestListTableFinished = ref(null)
  const refMaterialRequestListTableInQueue = ref(null)
  const refMaterialRequestListTableVoid = ref(null)
  const tableColumns = [
    { key: 'Number', sortable: true },
    { key: 'To', sortable: true },
    { key: 'From', sortable: true },
    { key: 'Type', sortable: true },
    { key: 'Reason', sortable: true },
    { key: 'QtyReq', sortable: true, title: 'Qty Requested' },
    { key: 'QtyAcc', sortable: true, title: 'Qty Accepted' },
    { key: 'QtySent', sortable: true, title: 'Qty Sent' },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsFinished = [
    { key: 'Number', sortable: true },
    { key: 'To', sortable: true },
    { key: 'From', sortable: true },
    { key: 'Reason', sortable: true },
    { key: 'QtyReq', sortable: true, title: 'Qty Requested' },
    { key: 'QtyAcc', sortable: true, title: 'Qty Accepted' },
    { key: 'QtySent', sortable: true, title: 'Qty Sent' },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsInQueue = [
    { key: 'Number', sortable: true },
    { key: 'To', sortable: true },
    { key: 'From', sortable: true },
    { key: 'Reason', sortable: true },
    { key: 'QtyReq', sortable: true, title: 'Qty Requested' },
    { key: 'QtyAcc', sortable: true, title: 'Qty Accepted' },
    { key: 'QtySent', sortable: true, title: 'Qty Sent' },
    { key: 'DateCreated', sortable: true },
    { key: 'actions' },
  ]
  const tableColumnsVoid = [
    { key: 'Number', sortable: true },
    { key: 'To', sortable: true },
    { key: 'From', sortable: true },
    { key: 'Reason', sortable: true },
    { key: 'QtyReq', sortable: true, title: 'Qty Requested' },
    { key: 'QtyAcc', sortable: true, title: 'Qty Accepted' },
    { key: 'QtySent', sortable: true, title: 'Qty Sent' },
    { key: 'DateCreated', sortable: true },
    { key: 'VoidBy', sortable: false },
    { key: 'VoidTime', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const perPageFinished = ref(10)
  const totalItemsFinished = ref(0)
  const currentPageFinished = ref(1)
  const searchQueryFinished = ref('')
  const perPageInQueue = ref(10)
  const totalItemsInQueue = ref(0)
  const currentPageInQueue = ref(1)
  const searchQueryInQueue = ref('')
  const perPageVoid = ref(10)
  const totalItemsVoid = ref(0)
  const currentPageVoid = ref(1)
  const searchQueryVoid = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const statusFilter = ref(null)
  const startDateFilter = ref('')
  const endDateFilter = ref('')
  const startDateFilterFinished = ref('')
  const endDateFilterFinished = ref('')
  const startDateFilterInQueue = ref('')
  const endDateFilterInQueue = ref('')
  const startDateFilterVoid = ref('')
  const endDateFilterVoid = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refMaterialRequestListTable.value ? refMaterialRequestListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const dataMetaFinished = computed(() => {
    const localItemsCountFinished = refMaterialRequestListTableFinished.value ? refMaterialRequestListTableFinished.value.localItems.length : 0
    return {
      from: perPageFinished.value * (currentPageFinished.value - 1) + (localItemsCountFinished ? 1 : 0),
      to: perPageFinished.value * (currentPageFinished.value - 1) + localItemsCountFinished,
      of: totalItemsFinished.value,
    }
  })

  const dataMetaInQueue = computed(() => {
    const localItemsCountInQueue = refMaterialRequestListTableInQueue.value ? refMaterialRequestListTableInQueue.value.localItems.length : 0
    return {
      from: perPageInQueue.value * (currentPageInQueue.value - 1) + (localItemsCountInQueue ? 1 : 0),
      to: perPageInQueue.value * (currentPageInQueue.value - 1) + localItemsCountInQueue,
      of: totalItemsInQueue.value,
    }
  })

  const dataMetaVoid = computed(() => {
    const localItemsCountVoid = refMaterialRequestListTableVoid.value ? refMaterialRequestListTableVoid.value.localItems.length : 0
    return {
      from: perPageVoid.value * (currentPageVoid.value - 1) + (localItemsCountVoid ? 1 : 0),
      to: perPageVoid.value * (currentPageVoid.value - 1) + localItemsCountVoid,
      of: totalItemsVoid.value,
    }
  })

  const refetchData = () => {
    refMaterialRequestListTable.value.refresh()
  }

  const refetchDataFinished = () => {
    refMaterialRequestListTableFinished.value.refresh()
  }

  const refetchDataInQueue = () => {
    refMaterialRequestListTableInQueue.value.refresh()
  }

  const refetchDataVoid = () => {
    refMaterialRequestListTableVoid.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, statusFilter, sortBy, isSortDirDesc, currentPageFinished, perPageFinished, searchQueryFinished, startDateFilter, endDateFilter, startDateFilterFinished, endDateFilterFinished, currentPageInQueue, perPageInQueue, searchQueryInQueue, startDateFilterInQueue, endDateFilterInQueue, currentPageVoid, perPageVoid, searchQueryVoid, startDateFilterVoid, endDateFilterVoid], () => {
    refetchData()
    refetchDataFinished()
    refetchDataInQueue()
    refetchDataVoid()
  })

  const fetchMaterialRequests = (ctx, callback) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    const to = perPage.value * (currentPage.value - 1)
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_MATERIAL_REQUEST}?limit=${perPage.value}&offset=${to}&search=${searchQuery.value}&type=ongoing&date_start=${startDateFilter.value}&date_end=${endDateFilter.value}`, { headers })
      .then(response => {
        totalItems.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(e => {
        if (e.message === 'Request failed with status code 401') {
         router.push({ name: 'auth-login' })
        } else {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching data list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  const fetchMaterialRequestsFinished = (ctx, callback) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    const toFinished = perPageFinished.value * (currentPageFinished.value - 1)
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_MATERIAL_REQUEST}?limit=${perPageFinished.value}&offset=${toFinished}&search=${searchQueryFinished.value}&type=finished&date_start=${startDateFilterFinished.value}&date_end=${endDateFilterFinished.value}`, { headers })
      .then(response => {
        totalItemsFinished.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchMaterialRequestsInQueue = (ctx, callback) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    const toInQueue = perPageInQueue.value * (currentPageInQueue.value - 1)
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_MATERIAL_REQUEST}?limit=${perPageInQueue.value}&offset=${toInQueue}&search=${searchQueryInQueue.value}&type=inqueue&date_start=${startDateFilterInQueue.value}&date_end=${endDateFilterInQueue.value}&in_queue=true`, { headers })
      .then(response => {
        totalItemsInQueue.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchMaterialRequestsVoid = (ctx, callback) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userToken}`,
    }
    const toVoid = perPageVoid.value * (currentPageVoid.value - 1)
    axios
      .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_MATERIAL_REQUEST}?limit=${perPageVoid.value}&offset=${toVoid}&search=${searchQueryVoid.value}&type=void&date_start=${startDateFilterVoid.value}&date_end=${endDateFilterVoid.value}&void=true`, { headers })
      .then(response => {
        totalItemsVoid.value = response.data.data_count
        callback(response.data.data)
      })
      .catch(error => {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching data list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'super-admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'super-admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchMaterialRequests,
    fetchMaterialRequestsFinished,
    fetchMaterialRequestsInQueue,
    fetchMaterialRequestsVoid,
    tableColumns,
    tableColumnsFinished,
    tableColumnsInQueue,
    tableColumnsVoid,
    perPageFinished,
    currentPageVoid,
    currentPageFinished,
    totalItemsFinished,
    totalItemsVoid,
    perPageInQueue,
    perPageVoid,
    currentPageInQueue,
    totalItemsInQueue,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    dataMetaFinished,
    dataMetaInQueue,
    dataMetaVoid,
    perPageOptions,
    searchQueryFinished,
    searchQueryInQueue,
    searchQueryVoid,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMaterialRequestListTable,
    refMaterialRequestListTableFinished,
    refMaterialRequestListTableInQueue,
    refMaterialRequestListTableVoid,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    refetchDataFinished,
    refetchDataInQueue,
    refetchDataVoid,
    // Extra Filters
    roleFilter,
    statusFilter,
    startDateFilter,
    endDateFilter,
    startDateFilterFinished,
    endDateFilterFinished,
    startDateFilterInQueue,
    endDateFilterInQueue,
    startDateFilterVoid,
    endDateFilterVoid,
  }
}
